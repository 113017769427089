exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adminers-js": () => import("./../../../src/pages/adminers.js" /* webpackChunkName: "component---src-pages-adminers-js" */),
  "component---src-pages-createrecords-js": () => import("./../../../src/pages/createrecords.js" /* webpackChunkName: "component---src-pages-createrecords-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-grouppurchasecommander-js": () => import("./../../../src/pages/grouppurchasecommander.js" /* webpackChunkName: "component---src-pages-grouppurchasecommander-js" */),
  "component---src-pages-imageslist-js": () => import("./../../../src/pages/imageslist.js" /* webpackChunkName: "component---src-pages-imageslist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lenscreate-js": () => import("./../../../src/pages/lenscreate.js" /* webpackChunkName: "component---src-pages-lenscreate-js" */),
  "component---src-pages-lensmanagelist-js": () => import("./../../../src/pages/lensmanagelist.js" /* webpackChunkName: "component---src-pages-lensmanagelist-js" */),
  "component---src-pages-lensprice-js": () => import("./../../../src/pages/lensprice.js" /* webpackChunkName: "component---src-pages-lensprice-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logistic-js": () => import("./../../../src/pages/logistic.js" /* webpackChunkName: "component---src-pages-logistic-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-navstatistic-js": () => import("./../../../src/pages/navstatistic.js" /* webpackChunkName: "component---src-pages-navstatistic-js" */),
  "component---src-pages-productsmanagelist-js": () => import("./../../../src/pages/productsmanagelist.js" /* webpackChunkName: "component---src-pages-productsmanagelist-js" */),
  "component---src-pages-purchaserecords-js": () => import("./../../../src/pages/purchaserecords.js" /* webpackChunkName: "component---src-pages-purchaserecords-js" */),
  "component---src-pages-queryeditrecords-js": () => import("./../../../src/pages/queryeditrecords.js" /* webpackChunkName: "component---src-pages-queryeditrecords-js" */),
  "component---src-pages-sendexpressaccount-js": () => import("./../../../src/pages/sendexpressaccount.js" /* webpackChunkName: "component---src-pages-sendexpressaccount-js" */),
  "component---src-pages-statisticrecords-js": () => import("./../../../src/pages/statisticrecords.js" /* webpackChunkName: "component---src-pages-statisticrecords-js" */),
  "component---src-pages-storemanage-js": () => import("./../../../src/pages/storemanage.js" /* webpackChunkName: "component---src-pages-storemanage-js" */),
  "component---src-pages-wxbanner-js": () => import("./../../../src/pages/wxbanner.js" /* webpackChunkName: "component---src-pages-wxbanner-js" */),
  "component---src-pages-wxcategory-js": () => import("./../../../src/pages/wxcategory.js" /* webpackChunkName: "component---src-pages-wxcategory-js" */),
  "component---src-pages-wxchannels-js": () => import("./../../../src/pages/wxchannels.js" /* webpackChunkName: "component---src-pages-wxchannels-js" */),
  "component---src-pages-wxcolorprice-js": () => import("./../../../src/pages/wxcolorprice.js" /* webpackChunkName: "component---src-pages-wxcolorprice-js" */),
  "component---src-pages-wxgoods-js": () => import("./../../../src/pages/wxgoods.js" /* webpackChunkName: "component---src-pages-wxgoods-js" */),
  "component---src-pages-wxnotices-js": () => import("./../../../src/pages/wxnotices.js" /* webpackChunkName: "component---src-pages-wxnotices-js" */),
  "component---src-pages-wxpayaccount-js": () => import("./../../../src/pages/wxpayaccount.js" /* webpackChunkName: "component---src-pages-wxpayaccount-js" */),
  "component---src-pages-wxspecialgoods-js": () => import("./../../../src/pages/wxspecialgoods.js" /* webpackChunkName: "component---src-pages-wxspecialgoods-js" */)
}

